import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

const ChangePassword = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
  
    const handleSubmit = (e) => {
      e.preventDefault();
     
    };
  
    const navigate = useNavigate();
    
  
    return (
      <div className="container">
        {/* <form onSubmit={handleSubmit}> */}
          <h3>Forgot password</h3>
          <div className="form-group">
            <label>Email address</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
  
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="row">
          <button type="submit" className="btn btn-primary btn-block"> Submit</button>
               
      </div>
      </div>
    );

};
export default ChangePassword;