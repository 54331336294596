// src/RegisterOrganization.js

import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const CreateOrganization = () => {
  const [IsLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({ adminname:'', name: '', mobile1:'', mobile2:'', email: '', address1: '', address2: '' })
  const [errors, setErrors] = useState({});
  const [redvalue, setredvalue] = useState("");
  const [adminDdl, setAdminDdl] = useState([]);
  
  const handleInput = (event) => {
    debugger;
    const dataObj = { ...values, [event.target.name]: event.target.value }
    setValues(dataObj)
};

const navigate = useNavigate();

function Validation(values) {
  const errors = {};
  let validationFlag = true;
  if (values.adminname === "" || values.adminname === "Select") {
    errors.adminname = "Admin name is required";
    errors.validationFlag = false;
}
  if (values.name === "") {
      errors.name = "Name is required";
      errors.validationFlag = false;
  }
  if (values.mobile1 === "") {
      errors.mobile1 = "Mobile 1 is required!";
      errors.validationflag = false;
  }
  if (values.email === "") {
      errors.email = "Email is required!";
      errors.validationFlag = false;
  }
  if (values.address1 === "") {
      errors.address1 = "Address line 1 is required!";
      errors.validationFlag = false;
  }
  return errors;
}

  function handleSubmit(event) {
    event.preventDefault();
    const errorMsg = Validation(values)
    setErrors(errorMsg);
    debugger;
    if (errorMsg.validationFlag == false) { return false; }

    var data = JSON.stringify({
      "admin_id": values.adminname,
      "name": values.name,
      "mobile_1": values.mobile1,
      "mobile_2": values.mobile2,
      "email": values.email,
      "address_1": values.address1,
      "address_2": values.address2
    });
    const BASE_URL=window.BASE_URL+'/add_organization';
    setIsLoading(true);
    var config = {
      method: 'post',
      url: BASE_URL,
  
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
        'Content-Type': 'application/json',
      },
      maxBodyLength: Infinity,
      data : data
    };
    axios.request(config)
    .then((response) => {
     
      if(response != null && response.data != null && response.data.results != null){
        setIsLoading(false);
        setredvalue("Organization is created successfully");
        navigate("/SuperAdmin/ManageOrganization");
      }
      else{
        setIsLoading(false);
      }
    })
    .catch((error) => {
      setIsLoading(false);
      console.log(error);
    });
  }
  useEffect(() => {
    let TokenVal = localStorage.getItem('jwtToken');
    if (TokenVal === null || TokenVal === "" || TokenVal === undefined) {
      navigate("/");
    }
   
    fnAdminDdlLoad();
  }, []);

  function fnAdminDdlLoad(){
    const BASE_URL=window.BASE_URL+'/get_admins';
    var config = {
      method: 'get',
      url: BASE_URL,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
      }
    };

    axios.request(config)
      .then(function (response) {
        setAdminDdl([]);
        
      if(response != null && response != undefined && response.data != null && response.data !=undefined && response.data.results != null && response.data.results.length > 0) {
        setAdminDdl(response.data.results);
      }
      })
      .catch(function (error) {
        console.log(error); 
      });
  }

  return (
    <div className="super-admin-container">
    <div className="super-admin-header">
        <h1>Create Organization</h1>
    </div>
    <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Select Admin</label>
          {/* <input type="text" placeholder="Select Admin" name="adminname" onChange={handleInput} /> */}
        <select name="adminname" onChange={handleInput}>
        <option>Select</option>
        {adminDdl.map((p) => (
          <option key={p.id} value={p.id}>
            {p.name}
          </option>
        ))}
        </select>          
        {errors.adminname && <span className="span-error">{errors.adminname}</span>}
        </div>
        <div className="form-group">
          <label>Name</label>
          <input type="text" placeholder="Organization Name" name="name" onChange={handleInput} />
          {errors.name && <span className="span-error">{errors.name}</span>}

        </div>
        <div className="form-group">
          <label>Mobile Number 1</label>
          <input type="text" placeholder="9337973843" name="mobile1" maxLength={10} onChange={handleInput} />
          {errors.mobile1 && <span className="span-error">{errors.mobile1}</span>}

        </div>
        <div className="form-group">
          <label>Mobile Number 2</label>
          <input type="text" placeholder="9337973844" name="mobile2" maxLength={10} onChange={handleInput} />

        </div>
        <div className="form-group">
          <label>E-Mail</label>
          <input type="email" placeholder="organization@gmail.com" name="email" onChange={handleInput} />
          {errors.email && <span className="span-error">{errors.email}</span>}

        </div>
        <div className="form-group">
          <label>Address Line 1</label>
          <input type="text" placeholder="House/Flat/Plot No., Street/Road" name="address1" onChange={handleInput} />
          {errors.address1 && <span className="span-error">{errors.address1}</span>}

        </div>
        <div className="form-group">
          <label>Address Line 2</label>
          <input type="text" placeholder="Area, District, State - Postal code" name="address2" onChange={handleInput} />

        </div>
        <button type="submit" className="super-admin-button">Submit</button>
       </form> 
       <div> <button type="button" className="btn btn-submit px-5">Back</button></div>
    </div>
  );
}

export default CreateOrganization;
