import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const ManageOrganization = () => {
  const [OrganizationListData, setOrganizationListData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    let TokenVal = localStorage.getItem('jwtToken');
    if (TokenVal === null || TokenVal === "" || TokenVal === undefined) {
      navigate("/");
    }
})
   // var axios = require('axios');
    const BASE_URL=window.BASE_URL+'/get_organizations_info';
    var config = {
      method: 'get',
      url: BASE_URL,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
      }
    };

    axios.request(config)
      .then(function (response) {
        setOrganizationListData([]);
        
      if(response != null && response != undefined && response.data != null && response.data !=undefined &&   response.data.results != null && response.data.results.length > 0)
        setOrganizationListData(response.data.results);
      })
      .catch(function (error) {
        console.log(error); 
      });
      const fnCreateOrganizationClick =  (e) => {
        navigate("/SuperAdmin/CreateOrganization");
      }

    return (
        <div className="super-admin-container">
            <div className="super-admin-header">
                <h1>Manage Organization</h1>
            </div>
            <div className="">
            <h3>List of Organizations</h3>
                        <table className="table table-hover">
                            <thead>
                                <tr className="text-center">
                                    <th>Name</th>
                                    <th>Mobile 1</th>
                                    <th>Mobile 2</th>
                                    <th>Email</th>
                                    <th>Address</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {OrganizationListData
                                //.filter((value) => {
                                    // if (SubmissionType === "ALL" && SubmissionStatus === "ALL") {
                                    //     if (searchedVal) {
                                    //         return value.customer_name.toLowerCase().includes(searchedVal.toLowerCase());
                                    //     }
                                    //     return true;
                                    // }
                                    // else if (SubmissionType && SubmissionStatus === "ALL") {
                                    //     if (searchedVal) {
                                    //         return value.customer_name.toLowerCase().includes(searchedVal.toLowerCase());
                                    //     }
                                    //     return value.submition_type === SubmissionType;
                                    // }

                                    // else if (SubmissionStatus) {
                                    //     if (searchedVal) {
                                    //         return value.customer_name.toLowerCase().includes(searchedVal.toLowerCase());
                                    //     }
                                    //     return value.submission_status.toLowerCase() === SubmissionStatus.toLowerCase();
                                    // }
                                    // else {
                                    //     if (searchedVal) {
                                    //         return value.customer_name.toLowerCase().includes(searchedVal.toLowerCase());
                                    //     }
                                    //     return true;
                                    // }
                                    
                                //})
                                .map((Data, idx) => (
                                    <tr className="text-center" id="addr0" key={idx}>
                                        <td>{Data.name}</td>
                                        <td>{Data.mobile_1}</td>
                                        <td>{Data.mobile_2}</td>
                                        <td>{Data.email}</td>
                                        <td>{Data.address_1 + ', '+ Data.address_2}</td>
                                        {/* <td><Button variant="primary" onClick={() => handleShow(index)}>
                                            Details
                                        </Button></td> */}
                                        {/*<td style={UserData.status_type === "APPROVED" ? { backgroundColor: 'green' } : UserData.status_type === "REJECTED" ? { backgroundColor: 'red' } : { backgroundColor: 'blue' } >{ UserData.status_type }</td>*/}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                   
            </div>
            <button className="super-admin-button" onClick={fnCreateOrganizationClick}>CREATE NEW ORGANIZATION</button>
        </div>
    );
};

export default ManageOrganization;