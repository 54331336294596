import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const ManageAdmin = () => {
  const [AdminListData, setAdminListData] = useState([]);
  const navigate = useNavigate();
 useEffect(() => {
  
    let TokenVal = localStorage.getItem('jwtToken');
    if (TokenVal === null || TokenVal === "" || TokenVal === undefined) {
      navigate("/");
    }
    const interval = setInterval(() => {
      fnAdmininfoLoad();
     },500);
     return () => clearInterval(interval);
}, [])

   function fnAdmininfoLoad(){
    
    // var axios = require('axios');
    const BASE_URL=window.BASE_URL+'/get_admins_info';
    var config = {
      method: 'get',
      url: BASE_URL,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
      }
    };
    axios.request(config)
    .then(function (response) {
      setAdminListData([]);
     
      if(response != null && response != undefined && response.data != null && response.data !=undefined &&   response.data.results != null && response.data.results.length > 0)
      setAdminListData(response.data.results);
    })
    .catch(function (error) {
      console.log(error); 
    });
   }
      const fnCreateAdminClick =  (e) => {
        navigate("/SuperAdmin/CreateAdmin");
      }

      const fnAdmininfoClick = (data, e) => {
        debugger;
      }

    return (
        <div className="super-admin-container">
            <div className="super-admin-header">
                <h1>Manage Admin</h1>
            </div>
            <div className="">
            <h3>List of Admins</h3>
                        <table className="table table-hover">
                            <thead>
                                <tr className="text-center">
                                    <th>Name</th>
                                    <th>Mobile</th>
                                    <th>Email</th>
                                    <th>Address</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {AdminListData
                                //.filter((value) => {
                                    // if (SubmissionType === "ALL" && SubmissionStatus === "ALL") {
                                    //     if (searchedVal) {
                                    //         return value.customer_name.toLowerCase().includes(searchedVal.toLowerCase());
                                    //     }
                                    //     return true;
                                    // }
                                    // else if (SubmissionType && SubmissionStatus === "ALL") {
                                    //     if (searchedVal) {
                                    //         return value.customer_name.toLowerCase().includes(searchedVal.toLowerCase());
                                    //     }
                                    //     return value.submition_type === SubmissionType;
                                    // }

                                    // else if (SubmissionStatus) {
                                    //     if (searchedVal) {
                                    //         return value.customer_name.toLowerCase().includes(searchedVal.toLowerCase());
                                    //     }
                                    //     return value.submission_status.toLowerCase() === SubmissionStatus.toLowerCase();
                                    // }
                                    // else {
                                    //     if (searchedVal) {
                                    //         return value.customer_name.toLowerCase().includes(searchedVal.toLowerCase());
                                    //     }
                                    //     return true;
                                    // }
                                    
                                //})
                                .map((Data, idx) => (
                                    <tr className="text-center" id="addr0" key={idx} onClick={() => fnAdmininfoClick(Data, idx)}>
                                        <td>{Data.name}</td>
                                        <td>{Data.mobile}</td>
                                        <td>{Data.email}</td>
                                        <td>{Data.address_1 + ', '+ Data.address_2}</td>
                                        {/* <td><button variant="primary" onClick={() => fnAdmininfoClick(idx)}>
                                            Admin Details
                                        </button></td>  */}
                                        {/*<td style={UserData.status_type === "APPROVED" ? { backgroundColor: 'green' } : UserData.status_type === "REJECTED" ? { backgroundColor: 'red' } : { backgroundColor: 'blue' } >{ UserData.status_type }</td>*/}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                   
            </div>
            <button className="super-admin-button" onClick={fnCreateAdminClick}>CREATE NEW ADMIN</button>
        </div>
    );
};

export default ManageAdmin;