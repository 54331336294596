import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const Dashboard = () => {
  const [userBal, setUserBal] = useState('');
  const [todayRecharge, setTodayRecharge] = useState('');
  const [todayRechargeAmount, setTodayRechargeAmount] = useState('');
  const [superAdminName, setSuperAdminName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');

  const navigate = useNavigate();
  const fnAdminClick =  (e) => {
    //saveTokenInLocalStorage(response.data.results.token);
    navigate("/SuperAdmin/ManageAdmin");
  }
  const fnManageOrganizationClick =  (e) => {
    navigate("/SuperAdmin/ManageOrganization");
  }

  const fnCreateDeviceClick =  (e) => {
    navigate("/SuperAdmin/CreateDevice");
  }

  const fnCreateCardClick = (e) => {
    navigate("/SuperAdmin/CreateCard");
  }


  useEffect(() => {
    let TokenVal = localStorage.getItem('jwtToken');
    fnDashboardLoad();
    if (TokenVal === null || TokenVal === "" || TokenVal === undefined) {
      navigate("/");
    }
});

function fnDashboardLoad(){
  const BASE_URL=window.BASE_URL+'/get_dashboardInfo';
  var config = {
    method: 'get',
    url: BASE_URL,
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
    }
  };

  axios.request(config)
    .then(function (response) {
      //setAdminDdl([]);
      debugger;
    if(response != null && response != undefined && response.data != null && response.data != undefined && response.data.results != null &&  response.data.results != undefined) {
      //setAdminDdl(response.data.results);
      setUserBal(response.data.results.userBalance);
      setTodayRecharge(response.data.results.todaysRecharges);
      setTodayRechargeAmount(response.data.results.rechargeAmount);
      if(response.data.results.userInfo != null && response.data.results.userInfo != undefined){
      setSuperAdminName(response.data.results.userInfo.name);
      setMobileNumber(response.data.results.userInfo.mobile);
      }
    }
    })
    .catch(function (error) {
      console.log(error); 
    });
}


  return (

    <div className="dashboard">
            <div className="super-admin-header">
                <h1>Manage Admin</h1>
            </div>
          
    <div className="dashboard">

      </div>
      <div className="balance-card">
        <div className="balance-header">
          <div className="balance-title">Max Print Tech</div>
        </div>
        <div className="balance-content">
          <div className="balance-units">Balance Units</div>
          <div className="balance-value"><label>{userBal}</label></div>
          <div className="super-admin">
            <span>Super Admin Name</span>
            <span><label>{superAdminName}</label></span>
          </div>
          <div className="mobile">
            <span>Mobile</span>
            <span><label>{mobileNumber}</label></span>
          </div>
        </div>
      </div>
      <div className="summary-cards">
        <div className="summary-card">
          <div className="summary-title"><label>{todayRecharge}</label></div>
          <div className="summary-subtitle">Today's Recharge</div>
        </div>
        <div className="summary-card">
          <div className="summary-title"><label>{todayRechargeAmount}</label></div>
          <div className="summary-subtitle">Today's Recharge Amount</div>
        </div>
      </div>
      <div className="menu-cards">
        <p className="menu-card" onClick={fnAdminClick}><i class="fa-solid fa-user"></i>Admins</p>
        <p className="menu-card" onClick={fnManageOrganizationClick}>Organizations</p> 
        <p className="menu-card" onClick={fnCreateDeviceClick}>Devices</p>
        {/* <p className="menu-card" onClick={fnCreateCardClick}>Cards</p> */}
      </div>
      
    </div>
  );
};

export default Dashboard;