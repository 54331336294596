// App.js
import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import LoginForm from "./Login";
import ChangePassword from "./ChangePassword";
import Dashboard from './SuperAdmin/Dashboard';
import ManageAdmin from './SuperAdmin/ManageAdmin';
import ManageOrganization from './SuperAdmin/ManageOrganization';
import CreateAdmin from './SuperAdmin/CreateAdmin';
import CreateOrganization from './SuperAdmin/CreateOrganization';
import CreateDevice from './SuperAdmin/CreateDevice';
import CreateCard from './SuperAdmin/CreateCard';

function App() {
  const [IsLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();
  const logout = () => {
      console.log(localStorage.removeItem('jwtToken'));
      localStorage.removeItem('jwtToken');
      console.log(localStorage.removeItem('jwtToken'));
      navigate("/");
  };
  useEffect(() => {
    setUserName(localStorage.Loginname);
});
  return (
    
    //  <div>
    //   <LoginForm />
    //   <RegistrationForm />
    
    //  </div>
      <div id="menuoff" className="handle-leftNav">
           <div className="left-nav">
                {/* <a href="..\Dashboard"><img src={loginlogo} width="55px" /></a> */}

                <ul className="navbar-nav me-auto mb-2 mt-3 mb-lg-0">
                    {/* <li className="nav-item">
    //                     <a className="nav-link" href="..\Dashboard">
    //                         <i class='fas fa-signal'></i>  Dashboard
    //                     </a>
    //                 </li> */}
                    {/* <li className="nav-item">
    //                     <a className="nav-link" href="..\ApprovalList">
    //                         <i class='fas fa-signal'></i>  Approval Client Access
    //                     </a>
    //                 </li> */}
                    {/* <li className="nav-item">
    //                     <a className="nav-link" href="..\DeltaProcess">
    //                         <i class='far fa-newspaper'></i> Delta NIK
    //                     </a>
    //                 </li> */}
                 </ul>

            </div>
           <div className="nav-top text-end p-2" id="header">
               {/* <span className="pe-5 span-title" id="Dashboardheader">APPROVAL NEW USER LIST</span> */}
               
               
               <a className="nav-link user-tile" href="#" onClick={logout}>
                 <span className="user-name">{userName}</span>
                    <i className="fas fa-power-off"></i>
                </a>
           </div>

            <div className="right-container">
          <Routes>
               <Route exact index path="/" element={<LoginForm />} />
               {/* <Route path="RegistrationForm" element={<RegistrationForm />} /> */}
               <Route exact path="ChangePassword" element={<ChangePassword />} />
               <Route path="/SuperAdmin/Dashboard" element={<Dashboard />} />
               <Route path="/SuperAdmin/ManageAdmin" element={<ManageAdmin />} />
               <Route path="/SuperAdmin/ManageOrganization" element={<ManageOrganization />} /> 
               <Route path="/SuperAdmin/CreateAdmin" element={<CreateAdmin />} /> 
               <Route path="/SuperAdmin/CreateOrganization" element={<CreateOrganization />} /> 
               <Route path="/SuperAdmin/CreateDevice" element={<CreateDevice />} /> 
               {/* <Route path="/SuperAdmin/CreateCard" element={<CreateCard />} />  */}
              </Routes>
              </div>

           <footer><center ><font size="2"><i class="fa-solid fa-copyright"></i> Max Card | version 1.0 | {new Date().getFullYear()}</font>  </center></footer>
        </div>
  );
}

export default App;
